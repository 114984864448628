import React from "react"
import Layout from "../../components/Layout/Layout"
import HomeLayout from "../../components/HomeLayout/HomeLayout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "../../components/HomeLayout/HomeLayout.scss"

const Flim = () => {
  const data = useStaticQuery(graphql`
    query {
      Flim: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Flim" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <div className="HomeLayout1">
        {data.Flim.nodes.map((img, index) => {
          var name = img.name.split(" ")

          return (
            <Link to={`/Services/Flim/${name[1]}`}>
              <Img
                fluid={img.childImageSharp.fluid}
                alt="Flim images"
                key={img.id}
              />
            </Link>
          )
        })}
        <div style={{ opacity: 0 }}>
          <Img fluid={data.Flim.nodes[0].childImageSharp.fluid} />
        </div>
        <div style={{ opacity: 0 }}>
          <Img fluid={data.Flim.nodes[0].childImageSharp.fluid} />
        </div>
        <div style={{ opacity: 0 }}>
          <Img fluid={data.Flim.nodes[0].childImageSharp.fluid} />
        </div>  
      </div>
    </Layout>
  )
}

export default Flim
